import React, { useCallback, useEffect, useState } from 'react';
import 'moment/locale/cs';
import { useDispatch, useSelector } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { remove as removeDiacritics } from 'diacritics';
import moment from 'moment/moment';
import jsPDF from 'jspdf';
import useFingerprint from 'use-fingerprint';

import PermanentFoodSection from '../../components/Kitchen/PermanentFoodSection';
import TodayMenuSection from '../../components/Kitchen/TodayMenuSection';
import { userLogout } from '../../redux/actions/userActions';
import { FINGERPRINT, WS_URL } from '../../constants';

const Kitchen = () => {
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(WS_URL, {
    share: false,
    shouldReconnect: () => true,
  });

  const [lastMessage, setLastMessage] = useState();

  const fingerprint = useFingerprint();

  const token = useSelector((state) => state.user.token);

  const dispatch = useDispatch();

  const handlePrintPermanentOrder = useCallback((user, food) => {
    const doc = new jsPDF('landscape', 'mm', 'a7');
    doc.setFont('times');
    doc.setFontSize(20);
    doc.text(removeDiacritics(`${user.firstname} ${user.surname}`), 52, 10, 'center');
    doc.text(removeDiacritics(food.name), 52, 20, 'center');
    doc.setFontSize(10);
    doc.text(removeDiacritics(moment().format('LLL')), 52, 30, 'center');
    doc.text('----------', 52, 70, 'center');
    doc.autoPrint();
    const w = window.open(doc.output('bloburl'), '_blank');
    const interval = setInterval(() => {
      if (w) {
        w.close();
      } else {
        clearInterval(interval);
      }
    }, 5000);
  }, []);

  useEffect(() => {
    if (fingerprint) {
      localStorage.setItem('fingerprint', fingerprint);
    }
  }, [fingerprint]);

  useEffect(() => {
    if (lastJsonMessage && readyState === ReadyState.OPEN && fingerprint === FINGERPRINT) {
      if ((!lastMessage || lastJsonMessage.id !== lastMessage?.id) && lastJsonMessage.user && lastJsonMessage.food) {
        const user = lastJsonMessage.user;
        const food = lastJsonMessage.food;
        handlePrintPermanentOrder(user, food);
        sendJsonMessage({ status: 'OK', userId: user.id });
      } else {
        sendJsonMessage({ status: 'ERROR', userId: lastJsonMessage && lastJsonMessage.user ? lastJsonMessage.user.id : undefined });
      }
      setLastMessage(lastJsonMessage);
    }
  }, [lastJsonMessage, readyState, handlePrintPermanentOrder]);

  useEffect(() => {
    // připojení komponenty - pokud je někdo přihlášen, odhlásím
    if (token) {
      dispatch(userLogout());
    }
    // odpojení komponenty - odhlásím uživatele
    return () => {
      dispatch(userLogout());
    };
  }, []);

  const onIdle = () => {
    if (token) {
      dispatch(userLogout());
    }
  };

  return (
    <div id="kitchen">
      <IdleTimer element={document} onIdle={onIdle} timeout={10000} />
      <PermanentFoodSection handlePrintPermanentOrder={handlePrintPermanentOrder} />
      <TodayMenuSection />
    </div>
  );
};

export default Kitchen;
